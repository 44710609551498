<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-checkbox
        slot="func-option"
        v-model="addSettlement"
        dense
        label="정산내역추가"
        class="mt-3"
      ></v-checkbox>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>

            <v-col cols="12" xs="12" class="mt-0">
              <v-text-field
                value="물품구매관리팀"
                label="담당부서"
                readonly
              ></v-text-field>
            </v-col>
            <!-- 거래처 -->
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.pInfo"
                label="거래처"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="6">
                  <v-select
                    v-model="form.status2"
                    :items="status2"
                    label="거래형태"
                    dense
                    menu-props="auto"
                    class="mt-5"
                    style="max-width: 220px;font-size: 0.785rem !important"
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                  <v-select
                    v-model="form.status"
                    :items="status"
                    label="관리상태"
                    dense
                    menu-props="auto"
                    class="mt-5"
                    style="font-size: 0.785rem !important"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12">
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="6">
                  <v-menu
                    ref="date1Selector"
                    v-model="date1Selector"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model.trim="form.date1"
                        label="일자"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                        style="width: 220px;"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.date1"
                      locale="ko"
                      @change="saveDate1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    ref="numStr2"
                    v-model.trim="form.str2"
                    v-validate="'required|max:11|is_not:0'"
                    :error-messages="errors.collect('str2Cost')"
                    data-vv-name="str2Cost"
                    required
                    label="*금액"
                    maxlength="11"
                    suffix="원"
                    placeholder="예) 300,000"
                    @keypress="preventComma"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str3"
                v-validate="'required|max:30'"
                data-vv-name="str3"
                maxlength="30"
                :error-messages="errors.collect('str3')"
                :counter="30"
                required
                label="물품*"
                placeholder="입력하세요(필수)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    v-model.trim="form.str4"
                    maxlength="50"
                    :counter="50"
                    label="규격"
                    placeholder="입력하세요"
                    class="mr-1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    v-model.trim="form.str5"
                    maxlength="30"
                    label="개수"
                    placeholder="입력하세요"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12">
              <v-textarea
                ref="txt1"
                v-model="form.str1"
                outlined
                label="내용"
                placeholder="입력하세요"
                no-resize
                rows="5"
                counter="200"
                :rules="[rules.txtCounter]"
                @update:error="chkValidate = false"
                class="mt-5"
              ></v-textarea>
            </v-col>
            <!-- 정보위치 -->
            <v-col cols="12" xs="12">
              <v-alert
                border="left"
                outlined
                type="info"
                dense
              >
                {{ form.pInfo }}
                <v-tooltip color="info" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small color="primary lighten-2"
                      @click="gotoDetail"
                      class="ml-1"
                    >mdi-launch</v-icon>
                  </template>
                  <span>바로가기</span>
                </v-tooltip>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// 구분: filters
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import isNumber from '@/filters/isNumber'

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      teamId: 3, // !! 물품구매팀 픽스!
      type: 47, // 유형(구매내역)
      depth: 2,
      pId: 0,
      pType: 46, // !! 부모는 거래처
      pInfo: '', // 정보위치(부모인 거래처 정보)
      isStar: 0,
      isSchedule: 1, // !! 일정에 보여야 한다
      subtype: 1, // !! 자동등록이 아니다
      gubun1: '구매내역',
      status: 1, // 구매상태
      status2: 1, // 거래형태
      str1: '', // 내용
      str2: '0', // 거래금액
      str3: '', // 물품(필수)
      str4: '', // 규격
      str5: '', // 갯수
      date1: '' // 구매일
    },
    // 구분: 관리상태
    status: [
      { text: '주문', value: 1 },
      { text: '수령', value: 2 },
      { text: '취소', value: 3 },
      { text: '반품', value: 4 },
      { text: '교환', value: 5 }
    ],
    // 구분: 거래형태
    status2: [
      { text: '구매', value: 1 },
      { text: '리스', value: 2 },
      { text: '렌탈', value: 3 }
    ],
    // 구분:
    index: -1, // !! 수정 인덱스
    // 구분: 수정시 처리자와 최종업데이트 정보
    viewWname: '', // 처리자를 보여주기만 하는 변수
    viewUpdatedAt: '', // 업데이트 일자를 보여주기 위한 변수
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        str3: '물품',
        str2Cost: '금액'
      }
    },
    // 구분: 입력 검증
    rules: {
      txtCounter: v => v.length <= 200 || '200자 이하의 내용을 입력해 주세요'
    },
    // 구분: txt1 유효성 체크
    chkValidate: true,
    // 구분: 일자
    date1Selector: false, // 구매일
    // 구분: 정산내역추가
    addSettlement: false
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  watch: {
    // !![2021.3.16] 수정
    'form.str2': {
      handler: function (val, oldVal) {
        if (!val) {
          this.form.str2 = '0'
          this.$refs.numStr2.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.form.str2 = val.replace(/(^0+)/, '')
          }
          this.form.str2 = numberFormatR(this.form.str2)
          this.form.str2 = numberFormat(this.form.str2)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.form.str2)) {
            this.form.str2 = oldVal
            this.$refs.numStr2.lazyValue = oldVal
          }
        }
      }
    }
  },

  methods: {
    isNumber, // 숫자만 인지 체크하는 필터
    numberFormat,
    numberFormatR,
    preventComma,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.form.id = 0
      this.form.teamId = 3 // !! 물품구매팀 픽스!
      this.form.type = 47 // 유형(구매내역)
      this.form.depth = 2

      this.form.pId = 0
      this.form.pType = 46 // !! 부모는 거래처 픽스!
      this.form.pInfo = '' // 정보위치(부모인 거래처 정보)
      this.form.isStar = 0
      this.form.isSchedule = 1 // !! 일정에 보여야 한다
      this.form.subtype = 1 // !! 자동등록이 아니다
      this.form.gubun1 = '구매내역'

      this.form.status = 1 // 관리상태
      this.form.status2 = 1 // 거래형태

      this.form.str1 = '' // 내용
      this.form.str2 = '0' // 거래금액
      this.form.str3 = '' // 물품(필수)
      this.form.str4 = '' // 규격
      this.form.str5 = '' // 갯수

      // !! 일자 초기화
      this.form.date1 = this.$moment().format('YYYY-MM-DD') // 구매일 초기값

      this.addSettlement = false // 정산내역추가 초기화

      this.index = -1 // 수정용 인덱스 초기화
    },
    async open (title, options, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // console.log(this.article) // !! props 로 넘어온 부모 데이터
        // 초기화
        await this.initDialog()

        // 중요: 등록시 - 부모로부터 넘겨받은 데이터로 pId와 pInfo 매칭
        this.form.pId = this.article.id // 거래처 아이디
        this.form.pInfo = this.article.name // 거래처명

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          this.index = index

          this.form.id = obj.id

          // 중요: 수정시 - 넘겨받은 자신의 객체에서 거래처 아이다/거래처명 매칭
          this.form.pId = obj.pId
          this.form.pInfo = obj.pInfo // 정보위치(부모인 거래처명)

          this.form.isStar = obj.isStar

          this.form.status = obj.status // 관리상태
          this.form.status2 = obj.status2 // 거래형태

          this.form.str1 = obj.str1 // 내용
          this.form.str2 = obj.str2 // 거래금액
          this.form.str3 = obj.str3 // 물품(필수)
          this.form.str4 = obj.str4 // 규격
          this.form.str5 = obj.str5 // 갯수

          this.form.date1 = obj.date1.substr(0, 10) // 구매일
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 등록/수정
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 중요: textarea 의 글자수가 200 이하로 조정되면 여기서 체크해서 처리한다.
        if (this.rules.txtCounter(this.$refs.txt1.value) === true) {
          this.chkValidate = true
        }
        if (!this.chkValidate) throw new Error('저장할 수 없습니다. 내용 항목의 글자수를 줄여주세요.')

        // 중요: 정산내역추가를 위해
        const rd1 = this.form.str2 // 금액
        let _arr = []
        _arr.push(this.form.str3) // 물품
        _arr.push(this.form.str4) // 규격
        _arr.push(this.form.str5) // 갯수
        // 내용: 구매일 물품*규격*갯수
        const rd2 = `${this.form.date1} ${_arr.join('*')}`

        // 한번더 - 거래금액에 대해 숫자만 남긴다
        // !![2021.3.16] 소용없으므로 주석처리 - watch 때문에 없앨수가 없다.
        // this.form.str2 = this.form.str2.replace(/[^0-9]/g, '')

        // 일자 처리 - 시분초를 붙여준다.(00:00:01 로 해서 일정에서 밑에 위치할 것이다.)
        this.form.date1 = `${this.form.date1.substr(0, 10)} 00:00:01` // 구매일

        // !! 실제 DB 입력 처리 (등록/수정 동일하게 처리)
        const { data } = await this.$axios.post(`lawork/lwc/setContentsOfShop`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // 결과 리턴용 객체 - 정산내역추가인 경우 returnResult.data 가 null 이 아니다.
        const returnResult = {
          type: this.addSettlement ? 'addSettlement' : 'success',
          data: this.addSettlement ? { rd1, rd2 } : null
        }

        await this.initDialog()
        this.resolve(returnResult)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        await this.initDialog() // 초기화
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 삭제
    async remove () {
      try {
        if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // console.log(this.form)
          const { data } = await this.$axios.get(`/lawork/case/deleteLwcommon/${this.form.id}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          await this.initDialog() // 초기화
          this.resolve('removed')
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 일자 셀렉트 선택시 저장 함수
    saveDate1 (date) {
      this.$refs.date1Selector.save(date)
    },
    // !! 거래처 상세페이지 바로가기
    gotoDetail () {
      this.dialog = false
      // !! 현재 경로가 shop 디렉토리가 아닌 경우만 shop/ 로 이동시킨다
      if (!(this.$router.history.current.path.indexOf('shop') !== -1)) {
        this.$router.push(`/shop/${this.form.pId}`)
      }
    }
  }
}
</script>
