// 업무상태에 대한 필터 함수를 정의한다.
export default (value) => {
  if (!value) return ''
  const statusArray = [
    { text: '주문', value: 1 },
    { text: '수령', value: 2 },
    { text: '취소', value: 3 },
    { text: '반품', value: 4 },
    { text: '교환', value: 5 }
  ]
  return statusArray.find(s => s.value === value).text
}
