// 업무상태에 대한 필터 함수를 정의한다.
export default (value) => {
  if (!value) return ''
  const statusArray = [
    { text: '구매', value: 1 },
    { text: '리스', value: 2 },
    { text: '렌탈', value: 3 }
  ]
  return statusArray.find(s => s.value === value).text
}
